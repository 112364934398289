import { Box, Container, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export function ErrorPage() {
    return (
        <Box>
            <Container sx={{ mt: 4 }}>
                <Grid container>
                    <Grid item md={7}>
                        <Typography
                            variant={'h1'}
                            sx={{ mb: 5 }}
                            fontWeight={'bold'}
                        >
                            404
                        </Typography>
                        <Typography variant={'h4'} sx={{ mb: 5 }}>
                            Its not you, its us and we now know about this
                            error. We will work on fixing this as soon as
                            possible
                        </Typography>

                        <div>
                            <Link className={'retry-button'} to={'/'}>
                                🔄 Go Home
                            </Link>
                        </div>
                    </Grid>
                    <Grid item md={5}></Grid>
                </Grid>
            </Container>
        </Box>
    )
}
