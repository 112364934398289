import React from 'react'
import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    Typography,
} from '@mui/joy'
import { capitalize } from '@mui/material'
import { colors } from '@/utilities/colors.ts'
import { Link, useNavigate } from 'react-router-dom'
import { formatPrice, sumAmounts } from '@/utilities/format-price.ts'
import { formatPropertyTitle } from '@/utilities/format-property-title'

import { Property } from '@/services/use-get-properties/types.ts'
import { getFullAddress } from '@/utilities/get-full-address'
import { isResidential, makePrural } from '@/utilities/property.tsx'
import { Text } from '@/components/text'
import { FontSize } from '@/components/text/types.ts'
import { groupedAmenities } from '@/utilities/groupedAmenities'
import { GetAmenityTypeIcon } from '@/src/pages/properties/[id]/components/property-details/components/GetAmenityType'
import { useUserChatController } from '@/services/use-user-messaging'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import Warning from '@/assets/svgs/warning.svg'
import { handlePropertyInformation } from '@/utilities/property-information.ts'
import analytics from '@/utilities/analytics'

interface IPropertyDetailsProps {
    property: Property
}
export function PropertyDetails(props: IPropertyDetailsProps) {
    const [loading, setLoading] = React.useState<boolean>(false)
    const groupedAmenitiesByTypes = groupedAmenities(
        props.property.property_amenities || []
    )
    const amenities = Object.entries(groupedAmenitiesByTypes)
    const navigate = useNavigate()
    const isResidentialProperty = isResidential(props.property)
    const { createConversation } = useUserChatController()
    const { user, isAuth } = useGetAuthenticatedUser()

    React.useEffect(() => {
        analytics.page()
    }, [])
    const coverImage = props.property.property_images[0]

    async function handleSendMessage(): Promise<void> {
        if (!isAuth) {
            analytics.track('unauthorized_user_send_message')
            navigate(`/auth/login?from=property/${props.property.id}`)
            return
        }

        setLoading(true)
        try {
            if (user) {
                const result = await createConversation(
                    user.id,
                    props.property.agent.id,
                    props.property.id,
                    coverImage.image_url,
                    props.property.agent.first_name,
                    user?.first_name || ''
                )
                analytics.track('user_sent_message', {
                    property_id: props.property.id,
                })
                if (result) {
                    setLoading(false)
                    navigate('/secure/messages')
                }
            }
        } catch (error) {
            console.error('Error creating conversation:', error)
        }
    }

    return (
        <Grid
            container
            sx={{
                mb: 4,
                mt: { sm: 4, md: 5 },
                color: colors.footerTextColor,
            }}
        >
            <Grid sm={12} xs={12} md={8}>
                <Typography sx={{ fontWeight: 600, fontSize: '1.52rem' }}>
                    {formatPropertyTitle[props.property.space_type](
                        props.property.residential_type
                    )}
                </Typography>
                <Typography sx={{ fontWeight: 300, my: 1 }}>
                    {getFullAddress({
                        address1: props.property.address1,
                        address2: props.property.address2,
                        neighbourhood:
                            props.property.neighbourhood.neighbourhood_name,
                        city: props.property.neighbourhood.region.region_name,
                    })}
                </Typography>
                <Box
                    sx={{
                        my: 1,
                        gap: 1,
                        borderTop: `1px solid ${colors.gray10}`,
                        borderBottom: `1px solid ${colors.gray10}`,
                        mr: 3,
                    }}
                >
                    {isResidentialProperty ? (
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            py={1}
                        >
                            <Box sx={{ py: 1, flex: 1, textAlign: 'center' }}>
                                <Box>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {props.property.number_of_bedrooms}{' '}
                                    </span>
                                    {makePrural(
                                        'bed',
                                        props.property.number_of_bedrooms,
                                        's'
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    py: 1,
                                    flex: 1,
                                    borderRight: `1px solid ${colors.gray10}`,
                                    borderLeft: `1px solid ${colors.gray10}`,
                                    textAlign: 'center',
                                }}
                            >
                                <Box>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {props.property.number_of_bathrooms}{' '}
                                    </span>
                                    {makePrural(
                                        'bath',
                                        props.property.number_of_bathrooms,
                                        's'
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    py: 1,
                                    flex: 1,
                                    textAlign: 'center',
                                    borderRight: `1px solid ${colors.gray10}`,
                                }}
                            >
                                <Box>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {props.property.space_type
                                            ?.split('_')
                                            .join(' ')}
                                    </span>
                                </Box>
                            </Box>
                            <Box sx={{ py: 1, flex: 1, textAlign: 'center' }}>
                                <Box>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {props.property.property_floor_size}{' '}
                                        Sqft.
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            py={1}
                        >
                            <Box sx={{ py: 1, textAlign: 'center' }}>
                                <Box>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {props.property.property_floor_size}{' '}
                                        Sqft.
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
                <Text sx={{ mt: 3 }} textFontSize={FontSize.Title}>
                    About this property
                </Text>
                <Text sx={{ mb: 2, mt: 1 }}>
                    {capitalize(props.property.overview)}
                </Text>
                {props.property.property_information.length > 0 && (
                    <Box
                        sx={{
                            mb: 3,
                            display: 'flex',
                            gap: 1,
                            fontWeight: 400,
                            alignItems: 'center',
                        }}
                    >
                        <img src={Warning} alt="warning/info" />
                        <Text>
                            {handlePropertyInformation(
                                props.property.property_information
                            )}
                        </Text>
                    </Box>
                )}
                <Divider sx={{ mr: 3 }} />
                {amenities.length > 0 && (
                    <>
                        <Text
                            textFontSize={FontSize.Title}
                            sx={{ my: 2, fontWeight: 600 }}
                        >
                            Additional Detail
                        </Text>
                        <Box sx={{ mb: 4 }}>
                            <List
                                orientation="horizontal"
                                wrap
                                sx={{ columnGap: '2rem', rowGap: '3rem' }}
                            >
                                {amenities.map(([category, items]) => (
                                    <ListItem
                                        key={category}
                                        nested
                                        sx={{
                                            backgroundColor: colors.white,
                                        }}
                                    >
                                        <ListItem
                                            sx={{
                                                flexDirection: 'column',
                                                p: 0,
                                                alignItems: 'start',
                                                gap: 0,
                                            }}
                                        >
                                            <Box>
                                                {GetAmenityTypeIcon(category)}
                                            </Box>
                                            <Text
                                                sx={{
                                                    fontWeight: 600,
                                                    fontSize: 18,
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {category}
                                            </Text>
                                        </ListItem>
                                        <List>
                                            {items.map((item, index) => (
                                                <ListItem
                                                    key={index}
                                                    sx={{ marginBottom: 0.5 }}
                                                >
                                                    {item}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Divider sx={{ mt: 2, mr: 3 }} />
                    </>
                )}
            </Grid>
            <Grid
                sm={12}
                xs={12}
                md={4}
                sx={{
                    border: `1px solid ${colors.primary}`,
                    borderRadius: 3,
                    p: 1.2,
                    height: 'max-content',
                }}
            >
                <Box sx={{ display: 'flex', pl: 1, alignItems: 'center' }}>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'left',
                            fontWeight: 300,
                            fontSize: '0.85rem',
                        }}
                    >
                        Rental term
                    </Typography>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'right',
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            display: 'flex',
                        }}
                    >
                        {capitalize(
                            props.property.rental_term.toLocaleLowerCase()
                        )}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', pl: 1, alignItems: 'center' }}>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'left',
                            fontWeight: 300,
                            fontSize: '0.85rem',
                        }}
                    >
                        Price
                    </Typography>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'right',
                            fontSize: '1.2rem',
                            fontWeight: 600,
                            display: 'flex',
                        }}
                    >
                        {formatPrice(props.property.price)}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', pl: 1, alignItems: 'center' }}>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'left',
                            fontWeight: 300,
                            fontSize: '0.85rem',
                        }}
                    >
                        Service charge
                    </Typography>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'right',
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            display: 'flex',
                        }}
                    >
                        {formatPrice(props.property.service_charge || 0)}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', pl: 1, alignItems: 'center' }}>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'left',
                            fontWeight: 300,
                            fontSize: '0.85rem',
                        }}
                    >
                        Security deposit (Refundable)
                    </Typography>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'right',
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            display: 'flex',
                        }}
                    >
                        {formatPrice(props.property.security_deposit || 0)}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ display: 'flex', pl: 1 }}>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'left',
                            fontWeight: 700,
                            fontSize: '0.95rem',
                        }}
                    >
                        Total
                    </Typography>
                    <Typography
                        sx={{
                            flex: 1,
                            textAlign: 'left',
                            fontSize: '1.5rem',
                            fontWeight: 700,
                            display: 'flex',
                        }}
                    >
                        {sumAmounts(
                            props.property.price,
                            props.property.service_charge
                        )}
                    </Typography>
                </Box>

                {props.property.agent.id !== user?.id && (
                    <Button
                        sx={{
                            width: '100%',
                            my: 2,
                            color: 'white',
                        }}
                        onClick={handleSendMessage}
                        loading={loading}
                    >
                        Send Message
                    </Button>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 300,
                    }}
                >
                    {!isAuth && (
                        <Box display="block">
                            <Typography
                                level={'body-xs'}
                                sx={{
                                    textAlign: 'center',
                                    maxWidth: '80%',
                                    mx: 'auto',
                                    mb: 2,
                                    fontWeight: 300,
                                }}
                            >
                                You need to login before you can contact
                                landlord or make a request to view the property
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontWeight: 300,
                                }}
                            >
                                <Typography level={'body-sm'}>
                                    Don’t have an account?
                                </Typography>
                                <Link to="/auth/sign-up">
                                    <Typography
                                        level={'body-sm'}
                                        sx={{
                                            color: colors.primary,
                                            ml: 0.5,
                                        }}
                                    >
                                        Create an account
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    )
}
