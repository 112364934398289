import React from 'react'
import { Header } from '@/src/components/header/index.tsx'
import { Footer } from '@/src/components/footer/index.tsx'
import { PropertyList } from '@/src/pages/home/components/property-list/index.tsx'
import { HowOpenPropsCanHelp } from '@/src/pages/home/components/how-open-props-can-help/index.tsx'
import { CityList } from '@/src/pages/home/components/city-list/index.tsx'
import { Subscription } from '@/src/pages/home/components/subscription/index.tsx'
import { SubscriptionBanner } from '@/components/banner'
import { useGetAuthenticatedUser } from '@/services/use-get-auth-user'
import analytics from '@/utilities/analytics.ts'

export function Home() {
    const userQuery = useGetAuthenticatedUser()
    React.useEffect(() => {
        if (userQuery.user) {
            analytics.identify(userQuery.user.id, {
                user_id: userQuery.user.id,
                email: userQuery.user.email,
                first_name: userQuery.user.first_name,
            })
        }
    }, [])
    return (
        <React.Fragment>
            <SubscriptionBanner />
            <Header />
            <PropertyList />
            <CityList />
            <Subscription />
            <HowOpenPropsCanHelp />
            <Footer />
        </React.Fragment>
    )
}
