import React from 'react'
import GlobalStyles from '@mui/joy/GlobalStyles'
import { IconButton, Sheet, Stack, Typography, useTheme } from '@mui/joy'
import {
    openMessagesPane,
    toggleMessagesPane,
    toggleSidebar,
} from '@/utilities/message'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { useChatController } from '@/services/use-user-messaging/provider'

export default function Header() {
    const theme = useTheme()
    const isTablet = theme.breakpoints.down('lg')
    const { selectedConversation, ownerName } = useChatController()

    React.useEffect(() => {
        if (isTablet) {
            openMessagesPane()
        }
    }, [isTablet])
    return (
        <Sheet
            sx={{
                display: { xs: 'flex', sm: 'none' },
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 9995,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'sm',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Header-height': '52px',
                        [theme.breakpoints.up('lg')]: {
                            '--Header-height': '0px',
                        },
                    },
                })}
            />
            <Stack direction="row">
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: { xs: 'inline-flex', sm: 'none' },
                    }}
                    onClick={() => toggleMessagesPane()}
                    disabled={!selectedConversation}
                >
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <IconButton
                    onClick={() => toggleSidebar()}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                >
                    <MenuIcon />
                </IconButton>
            </Stack>
            {ownerName && (
                <Typography>
                    Chatting with: <b>{ownerName}</b>
                </Typography>
            )}
        </Sheet>
    )
}
